import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration

export const firebaseConfig = {
    apiKey: "AIzaSyDZMXnhTz5kooo6LbBazMmjf1bPiqjRQog",
    authDomain: "tempemails-6473b.firebaseapp.com",
    projectId: "tempemails-6473b",
    storageBucket: "tempemails-6473b.appspot.com",
    messagingSenderId: "24440449293",
    appId: "1:24440449293:web:56cafebd312709d0b9a844"
};



// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.

const firebaseApp = initializeApp(firebaseConfig);
let messaging;
try {
    messaging = getMessaging(firebaseApp);
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
}

export function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: 'BOl_Y1Zs1DkcpyIMRYdAhhd1LDetOqBAZGRTSih5r0LHxA7lYcUWmIPasGOnuzZs2oMFUMaj7Fx6DxCx7fLpU6U' }).then((currentToken) => {
                if (currentToken) {
                   console.log(currentToken);
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });        }
    })
}
