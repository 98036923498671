import React, {useRef, useState} from "react";
import dateformat from "dateformat";
import {getMessage, MessageObj} from "../DAO";
import Message from "./Message";
import Loading from "./Loading";
import Error from "./Error";
import "./MessageList.css";

interface InboxProps {
  inbox: MessageObj[];
  messageID?: string;
  username: string;
  reload: () => void;
}

interface MessageCache {
  [id: string]: MessageObj;
}

function MessageList(props: InboxProps) {
  const [viewedmessage, setViewedMessage] = useState<string>(props.messageID ? props.messageID : "");
  const [loading, setLoading] = useState<boolean>();
  const [messagedata, setMessageData] = useState<MessageObj>();
  const [error, setError] = useState<string>();
  const initialCache: MessageCache = {};
  const [messageCache, setMessageCache] = useState(initialCache);
  const listEl: any = useRef();
  let loadingTimeout: any;

  function showMessage(event: React.MouseEvent, id: string) {
    event.stopPropagation();
    event.preventDefault();
    setMessageData(undefined);
    setViewedMessage(id);
    if (messageCache[id]) {
      setMessageData(messageCache[id]);
    } else {
      loadingTimeout = setTimeout(() => setLoading(true), 1000);
      getMessage(props.username, id).then((data) => {
        messageCache[id] = data;
        setMessageCache(messageCache);
        setMessageData(data);
      }, (reason) => {
        if (reason === "rate limited") {
          setError("Uh oh! You need to slow down on the message button.");
        }
      }).finally(() => {
        if (!!loadingTimeout) {
          clearTimeout(loadingTimeout);
        }
        setLoading(false);
      });
    }
  }

  function base64_decode(s :string) {
    return decodeURIComponent(escape(atob(s)));
  }

  function parseSubject(subject: string) {
    const reg = /(b64:[A-Za-z0-9\+=/]*:)/mg;
    const splitstring = subject.split(reg);
    let returnstring = "";
    for (const sub of splitstring) {
      if (sub.startsWith("b64:")) {
        const reg64 = /b64:([A-Za-z0-9\+=/]*):/mg;
        const matches = reg64.exec(sub);
        if (matches) {
         returnstring = returnstring + (base64_decode(matches[1]));
        }
      } else {
        returnstring = returnstring + sub;
      }
    }
    return returnstring;
  }

  function closeMessage() {
    setMessageData(undefined);
    if (!!listEl && !!listEl.current) {
      const currentLocation = listEl.current.getBoundingClientRect().top;
      if (currentLocation < 0) {
        console.log("scrolling to 0,", currentLocation + window.scrollY);
        window.scrollTo(0, currentLocation + window.scrollY);
      }
    }
  }
  return (
    <div className="messagelist-container" ref={listEl}>
      {error &&
          <Error message={error} timeout={7000} unmount={() => setError(undefined)}/>
      }
      <div id={"messagelist-ad1"} className="messagelist-row" key="ad1">
        <div className="messagelist-row-link" >
          <div className="messagelist-from">
              Momentary Email
          </div>
          <div className="messagelist-date">
            {dateformat(new Date(), "mmm dd h:MMtt")}
          </div>
          <div className="messagelist-subject">
              <ins data-revive-zoneid="7" data-revive-target="_blank" data-revive-id="cf4c552d020c0a57df51edd12e2d0bbb"></ins>
          </div>
        </div>
      </div>
      {props.inbox.map((message: MessageObj) => (
        <div id={"messagelist-" + message.id} className="messagelist-row" key={message.id}>
          <a className="messagelist-row-link" href="" onClick={(evt) => showMessage(evt, message.id)}>
            <div className="messagelist-from">
              {message.from}
            </div>
            <div className="messagelist-date">
              {dateformat(new Date(message.date.replace("+0000", "Z")), "mmm dd h:MMtt")}
            </div>
            <div className="messagelist-subject">
              <span>{parseSubject(message.subject)}</span>
            </div>
          </a>
          {viewedmessage === message.id &&
              <>
                {loading === true &&
                    <Loading/>
                }
                {messagedata &&
                    <Message data={messagedata} username={props.username} unmount={() => closeMessage()}
                             reload={props.reload}/>
                }
              </>
          }
        </div>
      ))}
    </div>
  );
}

export default MessageList;
