import React from "react";
import {Helmet} from "react-helmet";
import Nav from "../components/Nav";
import "./ContactUs.css";
import {Link} from "react-router-dom";
import Footer from "../components/Footer";

function ContactUs() {
  return (
    <div className="page page-contactus page-internal">
      <Helmet>
        <title>Contact Us | Momentary Email - Temporary Disposable Address</title>
        <meta name="description"
              content="If you have any questions or comments about MomentaryEmail, feel free to get in touch."/>
        <link rel="canonical" href="https://momentaryemail.com/contact-us/" />

      </Helmet>
      <div className="contactus-top-container">
        <Nav/>
      </div>
      <section className="contactus-text-section">
        <h1>Have Questions or Comments?</h1>
        <p>
          We're happy to answer any questions or discuss any comments you might have. First, though, please take these
          notes into consideration.
        </p>

        <h2>MomentaryEmail cannot send you email.</h2>
        <p>
          MomentaryEmail is designed to accept email messages only, and cannot send any messages. If you see any messages
          coming from momentaryemail.com, please ignore and delete them - someone else is spoofing momentaryemail.com addresses. There
          is no way to send a message from a MomentaryEmail inbox to another email address.
        </p>

        <h2>Messages in your inbox may disappear anytime.</h2>
        <p>
          Due to the amount of incoming mail, older messages and inboxes that haven't received a message in a while may
          be automatically deleted to make space. If you're looking for a message that is older than 24 hours it is
          extremely likely that the message is gone.
        </p>

        <h2>Anyone can read your MomentaryEmail email.</h2>
        <p>
          Please see our <Link to="/privacy">Privacy Policy</Link> for more information. Since MomentaryEmail doesn't require
          any logins or passwords, please do not use your MomentaryEmail inbox for anything that you would like to be kept
          private or secure. MomentaryEmail addresses are meant to be temporary and disposable.
        </p>

        <h2>MomentaryEmail greylists every email server.</h2>
        <p>
          If MomentaryEmail receives a message from an email server it hasn't communicated with yet, that server goes onto
          something called a "greylist", where the server is told it needs to retry the message after a given period of
          time. Due to this, messages from new (to MomentaryEmail) servers may be delayed by at least fifteen minutes, and
          often times up to an hour.
        </p>

        <h2>MomentaryEmail doesn't allow large messages.</h2>
        <p>
          If you're trying to send large attachments to a MomentaryEmail inbox, they will be refused. The largest message
          allowed is 500k. Other attachments will be deleted automatically.
        </p>

        <h2>Still have a question or comment?</h2>
        <p>
          No problem - just email MomentaryEmail at <a
          href="mailto:D-2ulvxzccc2e@momentaryemail.com">D-2ulvxzccc2e@momentaryemail.com</a> (yes,
          you're emailing a MomentaryEmail inbox alias!).
        </p>
      </section>
      <Footer/>
    </div>
  );
}

export default ContactUs;
