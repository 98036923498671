import {Link, NavLink} from "react-router-dom";
import React from "react";
import {ReactComponent as Logo} from "../img/logo.svg";
import "./Footer.css";

function Footer() {
  const thisYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-logo">
        <Link to="/" aria-label="MomentaryEmail homepage"><Logo title="MomentaryEmail logo"/></Link>
      </div>
      <NavLink to="/how-it-works/" activeClassName="active">How MomentaryEmail Works</NavLink>
      <NavLink to="/contact-us/" activeClassName="active">Contact Us</NavLink>
      <NavLink to="/privacy/" activeClassName="active">Privacy Policy</NavLink>
      <NavLink to="/facebook-temporary-email/" activeClassName="active">Facebook Temporary Email</NavLink>
      <NavLink to="/linkedin-temporary-email/" activeClassName="active">Fake Email for LinkedIn</NavLink>
      <NavLink to="/tempmail/" activeClassName="active">Tempmail</NavLink>

      <div className="created">
        Created in United Kingdom &#169; {thisYear}
      </div>
    </footer>
  );
}

export default Footer;
