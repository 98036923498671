import React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import {Router, Route} from "react-router-dom";
import ReactGA from "react-ga";
import history from "./history";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Privacy from "./pages/Privacy";
import Inbox from "./pages/Inbox";
import "normalize.css";
import "./index.css";
import Facebook from "./pages/Facebook";
import howItWorks from "./pages/HowItWorks";
import LinkedIn from "./pages/LinkedIn";
import Tempmail from "./pages/Tempmail";

if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA);
  ReactGA.pageview("/");
  history.listen((location) => {
    // Double requestAnimationFrames because of react-helmet
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        ReactGA.pageview(location.pathname + location.search);
      });
    });
  });
}

function AppRouter() {
  return (
    <Router history={history}>
        <Route path="/" exact component={Home}/>
        <Route path="/facebook-temporary-email/" exact component={Facebook}/>
        <Route path="/linkedin-temporary-email/" exact component={LinkedIn}/>
      <Route path="/tempmail/" exact component={Tempmail}/>

      <Route path="/how-it-works/" exact component={howItWorks}/>
      <Route path="/contact-us/" exact component={ContactUs}/>
        <Route path="/privacy/" exact component={Privacy}/>
      <Route path="/inbox/:username/:messageID" component={Inbox}/>
      <Route path="/inbox/:username" component={Inbox}/>
    </Router>
  );
}

const container = document.getElementById("root");
let root ;
if (container!.hasChildNodes()) {
  console.log("hydrating");
  root = hydrateRoot(container!, <AppRouter/>);
} else {
  console.log("creating");
  root = createRoot(container!);
  root.render(<AppRouter/>);
}
