import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {getInbox, MessageObj} from "../DAO";
import Nav from "../components/Nav";
import Loading from "../components/Loading";
import MessageTotal from "../components/MessageTotal";
import MessageList from "../components/MessageList";
import Footer from "../components/Footer";
import Error from "../components/Error";
import {ReactComponent as Reload} from "../img/reload.svg";
import "./Inbox.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {requestPermission} from "../firebase";
import {getMessaging, onMessage } from "firebase/messaging";

interface InboxRouterProps {
  username: string;
  messageID?: string;
}

function Inbox({match}: RouteComponentProps<InboxRouterProps>) {
  const API_HOST = process.env.REACT_APP_API_HOST || "https://mailapi.sbc.workers.dev";
  const username = match.params.username;
  const messageID = match.params.messageID;
  const [loading, setLoading] = useState<boolean>();
  const [mounted, setMounted] = useState(false);
  const [inbox, setInbox] = useState<MessageObj[] | undefined>();
  const [altinbox, setAltInbox] = useState<string>();
  const [error, setError] = useState<string>();
  const [fade, setFade] = useState("");
  let loadingTimeout: any;
  let fadeTimeout: any;

  function updateInbox(firstTime: boolean) {
    loadingTimeout = setTimeout(() => setLoading(true), 1000);
    if (!firstTime) {
      setFade("fade");
    }
    // fetch(`${API_HOST}/mailbox/${username}`, FETCH_OPTIONS).then((response) => response.json()).then((data) => {
    getInbox(username).then((data) => {
      setInbox(data.messages);
      setAltInbox(data.altinbox);
      if (firstTime) {
        setMounted(true);
      } else {
        fadeTimeout = setTimeout(() => setFade(""), 300);
      }
    }, (reason) => {
      if (reason === "rate limited") {
        setError("Uh oh! You need to slow down on the reload button.");
        setFade("");
      }
    }).finally(() => {
      if (!!loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
      setLoading(false);
    });
  }

  let messaging;
  try {
    messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // ...
    });
  } catch (err) {
    console.error("Failed to initialize Firebase Messaging", err);
  }



  useEffect(() => {
    updateInbox(true);
    return () => {
      if (!!loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
      if (!!fadeTimeout) {
        clearTimeout(fadeTimeout);
      }
    };
  }, [username, fadeTimeout, loadingTimeout]);

  return (
    <div className="page page-inbox page-internal">
      <Helmet>
        <title>{username} inbox | Momentary Email - Temporary Disposable Address</title>
        <script defer src="https://ads.momentaryemail.com/www/delivery/asyncjs.php"></script>
        <link rel="canonical" href="https://momentaryemail.com/inbox/" />
        <meta name="description"
              content="View the mail messages sent to your temporary email inbox."/>
      </Helmet>
      <div className="inbox-top-container">
        <Nav compact={true}/>
      </div>
      <div className="inbox-header-ad-container">
        <div className="adspot inbox-header-ad-728">
          <ins data-revive-zoneid="4" data-revive-id="cf4c552d020c0a57df51edd12e2d0bbb"></ins>
        </div>
      </div>
      <section className="inbox-main-section">
       <div className="inbox-header-div">
        <h1 className="inbox-title">{username}@momentaryemail.com</h1>
        <a className={"rss-icon"} href={ API_HOST + "/mailbox/" + username + "?rss=1"} aria-label={"RSS feed for this inbox"}>
          <i className="bi bi-rss-fill" role="img" aria-label="RSS"></i></a>

         <a onClick={requestPermission} href="#" hidden={true} >Notifications (beta)</a>
       </div>
        {loading === true &&
        <Loading/>
        }
        {mounted &&
        <div className="inboxheader-container">
          <MessageTotal inbox={inbox}/>
          <button onClick={() => updateInbox(false)}><Reload/><span>Reload</span></button>
        </div>
        }
        <div className={"fade-container " + fade}>
          {mounted && inbox && inbox.length > 0 &&
          <MessageList messageID={messageID} inbox={inbox} username={username} reload={() => updateInbox(false)}/>
          }
        </div>
      </section>
      <section className="inbox-sponsor-section">
        <div className="triangle sponsor-triangle">&nbsp;</div>
        <div className="altinbox-container" >
          <h4>Alias address:</h4>
          {altinbox &&
          <p className="altinbox">{altinbox}@momentaryemail.com</p>
          }
          {!altinbox &&
            <Loading/>
          }
          <p>
            Email sent to the above alias address will also show up in this inbox. Use an alias address when you need a
            little bit more security - people cannot view the alias address inbox without knowing the original address.
          </p>
        </div>
        <div className="sponsor-container">
          <div className="adspot inbox-ad-568">
            <ins data-revive-zoneid="2" data-revive-id="cf4c552d020c0a57df51edd12e2d0bbb"></ins>
          </div>
          <div className="adspot inbox-ad-300">
            <ins data-revive-zoneid="1" data-revive-id="cf4c552d020c0a57df51edd12e2d0bbb"></ins>
          </div>
        </div>
      </section>
      <Footer/>
      {error &&
      <Error message={error} timeout={7000} unmount={() => setError(undefined)}/>
      }
    </div>
  );
}

export default Inbox;
